





















import { Component, Vue, Emit } from 'vue-property-decorator';
import VueRouter from 'vue-router';
import store from '../../../store';
import Error from '../../../components/contents/Error.vue';
import axios, {AxiosResponse, AxiosError} from 'axios';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);

async function validation(to: VueRouter) {
  /**
   * URLの認証を行う
   */
  const $to: any = to;
  const res = await new Promise((resolve, reject) => {
    axios.get<AxiosResponse>(`/wp-json/moc/account/password/${$to.params.key}`)
    .then( (response) => {
      resolve(response);
    })
    .catch( (e: AxiosError) => {
      reject(e);
    });
  });
  return res;
}

@Component({
  components: {
    Error,
  },
})
export default class EditPasswordParent extends Vue {
  private mail: string = '';
  private newPassword: string = '';
  private secretQuestAans: string = '';
  private status: number = 200;

  private stepNavi: StepNaviData[] = [
    {
      title: 'ご本人確認',
    },
    {
      title: 'パスワード<br class="is-hidden-tablet">再設定',
    },
    {
      title: 'パスワード<br class="is-hidden-tablet">再設定完了',
    },
  ];

  get isLogin() {
    return this.$store.getters['auth/isLogin'];
  }


  @Emit()
  private setMail(value: string): void {
    this.mail = value;
  }

  @Emit()
  private setNewPassword(value: string): void {
    this.newPassword = value;
  }

  @Emit()
  private setSecretQuestAans(value: string): void {
    this.secretQuestAans = value;
  }

  private async beforeRouteEnter(to: VueRouter, from: VueRouter, next: any) {
    let status: number = 200;
    try {
     await validation(to);
    } catch (e) {
      const $error: any = e.response;
      if (typeof $error.status !== 'undefined') {
        status = $error.status;
      } else {
        status = 500;
      }
    }
    next((vm: {
      status: number,
    }) => {
      vm.status = status;
    });
  }

  private async beforeRouteUpdate(to: VueRouter, from: VueRouter, next: any) {
    let status: number = 200;
    try {
     await validation(to);
    } catch (e) {
      const $error: any = e.response;
      if (typeof $error.status !== 'undefined') {
        status = $error.status;
      } else {
        status = 500;
      }
    }
    this.status = status;
    next();
  }
}
